.title {
  // background-color: #6c7ae0;
  overflow: hidden;
  // color: white;
  // font-family: Lato-Bold;
  font-size: 18px;
  position:relative;
  padding: 10px 0
}

.cancelled {
    text-decoration: line-through;
}